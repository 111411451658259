import React, { FunctionComponent } from "react"
import { PodcastShowLinks } from "../../components/podcast-show-links/podcast-show-links"
import { PodcastRecentEpisodes } from "../../components/podcast-recent-episodes/podcast-recent-episodes"
import {
  PodcastShow,
  PodcastEpisode,
  PodcastRecentEpisode
} from "../../podcast-types"
import { PodcastEpisodeHeader } from "./podcast-episode-header"
import { PodcastEpisodeContent } from "./podcast-episode-content"
import styles from "./podcast-episode.module.scss"

export interface PodcastEpisodeViewProps {
  show: PodcastShow
  episode: PodcastEpisode
  recentEpisodes: PodcastRecentEpisode[]
  showViewMore: boolean
}

export const PodcastEpisodeView: FunctionComponent<PodcastEpisodeViewProps> = ({
  show,
  episode,
  recentEpisodes,
  showViewMore
}) => (
  <div className={styles.episodeView}>
    <PodcastEpisodeHeader episode={episode} />
    <PodcastEpisodeContent episode={episode} />
    <PodcastShowLinks show={show} />
    <PodcastRecentEpisodes
      showViewMore={showViewMore}
      showSlug={show.slug}
      episodes={recentEpisodes}
    />
  </div>
)
