import React, { FunctionComponent } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { Link } from "gatsby"
import { PathPrefix } from "../../../../core/constants"
import {
  getBookLocaliser,
  formatScriptureReference
} from "../../../scriptures/mappers/scripture-reference-mapper"
import { PodcastEpisode } from "../../podcast-types"
import styles from "./podcast-episode.module.scss"
import { Markdown } from "@components/markdown/markdown"

export interface PodcastEpisodeContentProps {
  episode: PodcastEpisode
}

export const PodcastEpisodeContent: FunctionComponent<
  PodcastEpisodeContentProps
> = ({ episode }) => {
  const { formatMessage } = useIntl()
  const getLocalisedBook = getBookLocaliser(formatMessage)

  if (
    !episode.description &&
    !episode.transcript &&
    !episode.scriptures.length
  ) {
    return null
  }

  return (
    <div className={styles.episodeContentWrapper}>
      <div className={styles.episodeContent}>
        {episode.description && (
          <Markdown
            className={styles.episodeDescription}
            content={episode.description}
          />
        )}
        {episode.transcript && (
          <section>
            <h2 className={styles.episodeTranscriptTitle}>
              <FormattedMessage id="podcasts-transcript-title" />
            </h2>
            <Markdown
              className={styles.episodeTranscript}
              content={episode.transcript}
            />
          </section>
        )}
      </div>
    </div>
  )
}
