import React, { FunctionComponent } from "react"
import { graphql } from "gatsby"
import { Page } from "../../../components/page/page"
import { PageProps } from "../../../types/page"
import { mapPodcastEpisodeQueryToView } from "../mappers/podcast-episode-mappers"
import { PodcastEpisodeView } from "../views/podcast-episode/podcast-episode-view"
import { PodcastEpisodeQuery } from "../podcast-types"
import { defaultMetadata } from "@core/constants"

export const PodcastEpisodeTemplate: FunctionComponent<
  PageProps<PodcastEpisodeQuery>
> = ({ data, ...pageProps }) => {
  const { metadata, ...viewProps } = mapPodcastEpisodeQueryToView(
    data!,
    pageProps.pageContext
  )
  const podcastEpisodeData = data?.contentfulPodcastEpisode

  const metadataOverrides = {
    title:
      podcastEpisodeData?.metadata?.title ||
      metadata.title ||
      defaultMetadata.title,
    description:
      podcastEpisodeData?.metadata?.description ||
      metadata.description ||
      defaultMetadata.description,
    openGraphImage:
      podcastEpisodeData?.metadata?.image?.fixed?.src ||
      metadata.openGraphImage ||
      defaultMetadata.openGraphImage
  }

  return (
    <Page
      {...pageProps}
      pageOptions={{ headerVariant: "cream" }}
      metadata={{ ...metadata, ...metadataOverrides }}
    >
      <PodcastEpisodeView {...viewProps} />
    </Page>
  )
}

export default PodcastEpisodeTemplate

export const query = graphql`
  query PodcastEpisode(
    $showId: String
    $episodeId: String
    $recentEpisodeIds: [String]
  ) {
    contentfulPodcastShow(id: { eq: $showId }) {
      ...PodcastEpisodeShow
    }
    contentfulPodcastEpisode(id: { eq: $episodeId }) {
      ...PodcastEpisodeView
    }
    allContentfulPodcastEpisode(filter: { id: { in: $recentEpisodeIds } }) {
      nodes {
        ...RecentPodcastEpisode
      }
    }
  }
`
