import React, { FunctionComponent } from "react"
import classNames from "classnames"
import {
  PageHeader,
  HeaderTitle
} from "../../../../components/generic-page-header"
import { Audio } from "../../../../components/audio/audio"
import { AvatarList } from "../../../../components/avatar/avatar-list"
import { ShareAndLikeButton } from "../../../../components/share-and-like-button/share-and-like-button"
import { Breadcrumbs } from "../../../../components/breadcrumbs/breadcrumbs"
import { IconLabel } from "../../../../components/icon-label/icon-label"
import { PodcastEpisode } from "../../podcast-types"
import styles from "./podcast-episode.module.scss"

export interface PodcastEpisodeHeaderProps {
  episode: PodcastEpisode
}

export const PodcastEpisodeHeader: FunctionComponent<
  PodcastEpisodeHeaderProps
> = ({ episode }) => {
  const audioId = episode.id

  return (
    <PageHeader
      className={classNames([
        styles.episodeHeader,
        episode.image && styles.withImage
      ])}
    >
      <div className={styles.episodeHeaderContent}>
        {episode.formattedDate && (
          <IconLabel className={styles.episodeHeaderLabel} variant="12-audio">
            {episode.formattedSeasonAndEpisode || episode.formattedDate}
          </IconLabel>
        )}
        <Breadcrumbs
          className={styles.episodeHeaderBreadcrumbs}
          items={episode.breadcrumbs}
          size="sm"
        />
        <HeaderTitle
          className={styles.episodeHeaderTitle}
          title={episode.title}
        />
        <div className={styles.episodeHeaderMeta}>
          {Boolean(episode.teachers.length) && (
            <AvatarList avatars={episode.teachers} size="sm" showNames />
          )}
          <ShareAndLikeButton
            id={audioId}
            contentType="podcastEpisode"
            className={styles.episodeHeaderShare}
          />
        </div>
        {Boolean(episode.audioUrl) && (
          <Audio
            title={episode.title}
            audioId={audioId}
            className={styles.episodeHeaderAudio}
            src={episode.audioUrl}
          />
        )}
      </div>
      {episode.image && (
        <img
          className={styles.episodeHeaderImage}
          src={episode.image}
          alt={episode.title}
        />
      )}
    </PageHeader>
  )
}
